@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --display-font: "Roboto Mono", monospace;
  --body-font: "Roboto", sans-serif;
  --basic-size: 0.95rem;
  --theme-color1: #d61e93;
  --theme-color2: #1ec5c5;
  --theme-color3: #eed704;
  --theme-color4: #1f1e1e;
  --theme-color5: #05E96F;
  --theme-color4up: #652D90;
  --white-text: #dadada;
}

html {
  font-size: 18px;
  scroll-behavior: smooth;
}

h3 {
  font-size: var(--basic-size);
  line-height: 2rem;
}

a {
  text-decoration: none;
}

.App {
  color: #dadada;
  background-color: #141414;
  font-family: "Roboto Mono", monospace;
}

/* ---Navigation Bar --- */

.navBar {
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.8rem;
  background-color: black;
  font-family: var(--display-font);
}

.navBar ul {
  display: flex;
  list-style: none;
}

.navBar li {
  padding-left: 2.5rem;
  text-decoration: none;
  font-size: var(--basic-size);
  font-weight: 500;
}

.navBar a {
  text-decoration: none;
}

.logo {
  font-weight: 900;
}

p {
  font-family: var(--body-font);
}

/* Introduction  */

.introduction {
  display: flex;
  padding: 4vw auto;
  position: relative;
  min-height: 20rem;
}

.introduction img {
  width: 20rem;
  height: 20rem;
  padding: 2rem;
  margin: auto;
}

.introduction article {
  padding: 2rem 2rem 2rem 0px;
  margin: auto;
}

.introduction h4,
.introduction p {
  font-family: var(--body-font);
  font-size: 0.9rem;
  padding: 0.5rem 0px;
}

.intro-content {
  line-height: 2rem;
  padding-top: 1rem;
}

.intro-content p {
  padding: 1rem 0pc;
  line-height: 2rem;
}

.introduction h3 {
  padding-top: 1.75rem;
}

.introduction img {
  border-radius: 50%;
}

/* Projects  */
.title {
  padding-left: 40px;
}

#projects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-gap: 20px; */
  grid-row-gap: 4rem;
  grid-column-gap: 20px;
  padding: 1rem 0rem 4rem 0rem;
  margin: 0px 40px;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
}

.project a {
  color: #dadada;
  text-decoration: none;
}

h3 {
  font-size: var(--basic-size);
}

.project-preview {
  overflow: hidden;
  height: 27vw;
  /* transition: height 0.5s cubic-bezier(.5,0,.44,.99); */
}

.project-preview img {
  width: 75%;
  overflow: hidden;
  margin: 12% 10% 0% 15%;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

#project1 .project-preview {
  background-color: var(--theme-color1);
}

#project2 .project-preview {
  background-color: var(--theme-color2);
}

#project3 .project-preview {
  background-color: var(--theme-color3);
}

#project4 .project-preview {
  background-color: var(--theme-color4);
}

#project5 .project-preview {
  background-color: var(--theme-color5);
}

#projects h4 {
  padding-top: 0.75rem;
  font-size: 0.9rem;
  color: #dadada;
}

#projects p {
  color: #dadada;
  padding-top: 0.75rem;
  font-size: 0.9rem;
}

/* Footer */

footer {
  background-color: black;
  margin-top: 5rem;
  padding: 3rem 40px;
}

footer section {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  padding-top: 1.5rem;
}

footer ul {
  list-style: none;
}

footer li,
footer a {
  color: #dadada;
  display: flex;
  align-items: center;
  height: 3rem;
  word-wrap: break-word;
}

footer ul {
  margin-right: 10%;
}

footer nav {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

footer img {
  padding-right: 1rem;
}

footer .arrow-up {
  margin-left: auto;
  margin-top: auto;
}

.arrow-up img {
  width: 2.2rem;
  padding-right: 0px;
}

@media only screen and (max-width: 1200px) {
  .about p {
    font-size: 1rem;
    line-height: 2.6rem;
  }

  #projects {
    grid-template-columns: 1fr;
  }

  .project-preview {
    height: 60vw;
  }
}

@media only screen and (max-width: 850px) {
  .introduction {
    width: 100%;
  }

  .introduction img {
    display: none;
  }

  .introduction article {
    padding: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .navBar {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    padding: 15px 40px;
  }

  #logo {
    padding-top: 1rem;
  }

  .navBar h2 {
    padding-top: 1rem;
  }

  .navBar ul {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .navBar li {
    padding-left: 0px;
    font-size: 0.9rem;
  }

  #projects {
    padding-bottom: 0px;
  }

  footer {
    font-size: 0.9rem;
  }

  footer ul {
    margin-right: 0px;
  }
}

/* project page styling */

.project-theme1 {
  background-color: var(--theme-color1);
  color: var(--white-text);
}

.project-theme2 {
  background-color: var(--theme-color2);
  color: black;
}

.project-theme3 {
  background-color: var(--theme-color3);
  color: black;
}

.project-theme4 {
  background-color: var(--theme-color4);
  color: var(--white-text);
}

.project-theme4up {
  background-color: var(--theme-color4up);
  color: var(--white-text);
}

.project-theme5 {
  background-color: var(--theme-color5);
  color: black;
}

.about-theme {
  background-color: #00109F;
  color: var(--white-text);
}

.about-theme img {
  margin: 0 0 auto 0;
}

.skillset {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.skillset img {
  width: 4rem;
  height: auto;
  padding: 0;
}

.skillset p {
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
  align-items: center;
}

.project-intro article {
  height: calc(100vh - 4.8rem);
  padding: 5.5rem 10vw 0px 40px;
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  overflow: hidden;
  position: relative;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-intro section {
  padding-right: 4vw;
  padding-bottom: 5rem;
}

.project-intro h1 {
  margin-bottom: 1rem;
}

.white-text {
  color: var(--white-text);
}

.dark-text {
  color: black;
}

.project-intro p {
  margin: 1.7rem 0rem 1.7rem 0px;
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.img-preview {
  width: 100%;
  align-self: center;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.links {
  position: absolute;
  bottom: 1rem;
  display: flex;
}

.links a {
  display: flex;
  align-items: center;
  padding-right: 15px;
  font-size: 0.9rem;
}

.links a img {
  padding-right: 15px;
}

.tiles {
  column-count: 2;
  margin: 8rem 5rem;
  column-gap: 5rem;
  row-gap: 5rem;
}

.tiles article {
  /* background-color: var(--theme-color2); */
  height: fit-content;
  max-width: 25rem;
  min-width: 18rem;
  margin: 0px auto 4rem auto;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.tiles section {
  background-color: #1f1e1e;
  position: relative;
  padding: 2rem;
  right: 1rem;
  bottom: 1rem;
}

.tiles p {
  font-size: 0.9rem;
  line-height: 1.8rem;
  padding-bottom: 0.7rem;
}

.tiles h1 {
  line-height: 2rem;
  padding-bottom: 1rem;
  text-underline-offset: 4px;
}

.theme1-line {
  text-decoration: underline var(--theme-color1) 2px;
}

.theme2-line {
  text-decoration: underline var(--theme-color2) 2px;
}

.theme3-line {
  text-decoration: underline var(--theme-color3) 2px;
}

.theme4-line {
  text-decoration: underline #652D90 2px;
}

@media only screen and (max-width: 1000px) {
  .project-intro article {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .project-intro h1 {
    margin: 3rem 0px 1rem 0px;
  }

  .tiles {
    margin: 8rem 40px;
  }
}

@media only screen and (max-width: 800px) {
  .tiles {
    column-count: 1;
  }

  .tiles article {
    margin: 0px auto 4rem 0px;
    min-width: 15rem;
  }

  .tiles article:nth-child(even) {
    margin: 0px 0px 4rem auto;
  }

  .project-intro article {
    padding: 3.5rem 40px 0px 40px;
  }
}

@media only screen and (max-width: 600px) {
  .project-intro article {
    padding: 2rem 40px 0px 40px;
  }
}
